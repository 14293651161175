import { mapEasingToNativeEasing } from './easing.mjs';

function animateStyle(element, valueName, keyframes, { delay = 0, duration, repeat = 0, repeatType = "loop", ease, times, } = {}) {
    const keyframeOptions = { [valueName]: keyframes };
    if (times)
        keyframeOptions.offset = times;
    return element.animate(keyframeOptions, {
        delay,
        duration,
        easing: mapEasingToNativeEasing(ease),
        fill: "both",
        iterations: repeat + 1,
        direction: repeatType === "reverse" ? "alternate" : "normal",
    });
}

export { animateStyle };
